/* =================== MENU MOBILE FILTRO =================== */
jQuery(document).ready(function($) {

  //alert('teste');
  // Open menu
  $('#menu-btn-mobile-filtros').on('click', function() {
    toggleNav();
  });

  // Open submenu
  $('.has-children').children('a').on('click', function(event) {
    //prevent default clicking on direct children of .has-children 
    event.preventDefault();
    var selected = $(this);
    selected.next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('move-out');
    $('.cd-dropdown-filtros-content-filtros').scrollTop(0);
  });

  // Voltar
  $('.go-back').on('click', function() {
    var selected = $(this),
      visibleNav = $(this).parent('ul').parent('.has-children').parent('ul');
    selected.parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('move-out');
    $('.cd-dropdown-filtros-content-filtros').scrollTop(0);
  });

  // Close menu
  $('.cd-dropdown-wrapper-filtros').on('click', function(ev) {
    if (ev.target == this) {
      closeNav();
    }
  });

  // Close menu on swipe
  $('body').on('swipeleft', function(ev) {
    closeNav();
  });

  // Toggle menu
  function toggleNav() {
    var navIsVisible = (!$('.cd-dropdown-filtros').hasClass('dropdown-is-active')) ? true : false;
    $('.cd-dropdown-filtros').toggleClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-trigger-filtros').toggleClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-wrapper-filtros').toggleClass('active');
    $('#menu-btn-mobile-filtros').addClass('active');
    if (!navIsVisible) {
      $('.cd-dropdown-filtros').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
        $('.has-children ul').addClass('is-hidden');
        $('.move-out').removeClass('move-out');
        $('.is-active').removeClass('is-active');
        $('#menu-btn-mobile-filtros').removeClass('active');
      });
    }
  }

  // Open menu
  function openNav() {
    var navIsVisible = (!$('.cd-dropdown-filtros').hasClass('dropdown-is-active')) ? true : false;
    $('.cd-dropdown-filtros').addClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-trigger-filtros').addClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-wrapper-filtros').addClass('active');
    $('#menu-btn-mobile-filtros').addClass('active');
    if (!navIsVisible) {
      $('.cd-dropdown-filtros').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
        $('.has-children ul').addClass('is-hidden');
        $('.move-out').removeClass('move-out');
        $('.is-active').removeClass('is-active');
        $('#menu-btn-mobile-filtros').removeClass('active');
      });
    }
  }

  // Close menu
  function closeNav() {
    var navIsVisible = (!$('.cd-dropdown-filtros').hasClass('dropdown-is-active')) ? true : false;
    $('.cd-dropdown-filtros').removeClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-trigger-filtros').removeClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-wrapper-filtros').removeClass('active');
    $('#menu-btn-mobile-filtros').removeClass('active');
    if (!navIsVisible) {
      $('.cd-dropdown-filtros').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
        $('.has-children ul').addClass('is-hidden');
        $('.move-out').removeClass('move-out');
        $('.is-active').removeClass('is-active');
        $('#menu-btn-mobile-filtros').removeClass('active');
      });
    }
  }

});
