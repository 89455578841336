/* =================== DETALHE PRODUTO =================== */
jQuery(document).ready(function($){

	// Carrosel
	$('.produto-fotos-carrosel').slick({
		slidesToShow: 4,
    infinite: false,
    slidesToScroll: 1
  });

	// Exibe a foto no destaque ao clicar em alguma THUMB
	$('.produto-carrosel-thumb').on('click', function() {
		var img = $(this).data('img');
		$('.produto-foto-dest').find('img').attr('src',img);
	});
});
